<script setup lang="ts">

import MessageComponent from "@/message/MessageComponent.vue";
import Message from "@/message/message";
import {provide} from "vue";

const message = new Message();

provide('message', message);

</script>

<template>
  <router-view></router-view>
  <MessageComponent/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
</style>
