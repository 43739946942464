<script setup lang="ts">

import Message from "@/message/message";
import {inject} from "vue";

const message = inject('message') as Message;

</script>

<template>
  <div v-if="message.hasMessage()"
       class="d-flex justify-content-center align-items-center"
       :class="message.getColor() ==='gray'? 'overlap': ''"
  >
    <div class="loading-container">
      <div class="card loading-card"
           :class=message.getColor()
      >
        <h5 class="text-white m-0 px-3 py-2">{{ message.getMessage() }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

}

.loading-container {
  position: fixed;
  top: 10px;

  z-index: 1000;
}

.loading-card {
  background-color: gray;
}
</style>