import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import {localStorageData} from "@/common/localStorageData";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/user/infrastructure/views/Login.vue'),
        props: (route) => ({line_id: route.query.line_id})
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/user/infrastructure/views/Register.vue'),
        props: (route) => ({line_id: route.query.line_id})
    },
    {
        path: '/line/login',
        name: 'LineLogin',
        component: () => import('@/line/views/LineLogin.vue'),
    },
    {
        path: '/line/login_callback',
        name: 'LineLoginCallback',
        component: () => import('@/line/views/LineLoginCallback.vue'),
    },
    {
        path: "/manager_dashboard",
        name: "ManagerDashboard",
        component: () => import("@/dashboard/views/manager/Dashboard.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ['manager', 'admin'],
            redirect: 'Login'
        },
        children: [
            {
                path: "pinball",
                name: "Pinball",
                component: () => import("@/user/infrastructure/views/manager/PinBall.vue")
            },
            {
                path: "bonus",
                name: "Bonus",
                component: () => import("@/user/infrastructure/views/manager/Bonus.vue")
            },
            {
                path: "balance",
                name: "Balance",
                component: () => import("@/user/infrastructure/views/manager/Balance.vue")
            },
            {
                path: "overview",
                name: "ManagerOverview",
                component: () => import("@/overview/infrastructure/views/manager/Overview.vue")
            }, {
                path: "customer",
                name: "customer",
                component: () => import("@/user/infrastructure/views/manager/Customer.vue")
            },
            {
                path: "product",
                name: "Product",
                component: () => import("@/product/infrastructure/views/manager/Product.vue")
            },
            {
                path: "receipt_overview",
                name: "ReceiptOverview",
                component: () => import("@/receipt/infrastructure/views/manager/ReceiptOverview.vue")
            },
            {
                path: "product_receipt",
                name: "ProductReceipt",
                component: () => import("@/receipt/infrastructure/views/manager/ProductReceipt.vue")
            },
            {
                path: "user_receipt",
                name: "UserReceipt",
                component: () => import("@/receipt/infrastructure/views/manager/UserReceipt.vue")
            }
        ]
    },
    {
        path: "/user_dashboard",
        name: "UserDashboard",
        component: () => import("@/dashboard/views/user/Dashboard.vue"),
        meta: {
            requiresAuth: true,
            allowedRoles: ['user'],
            redirect: 'LineLogin'
        },
        children: [
            {
                path: "overview",
                name: "UserOverview",
                component: () => import("@/overview/infrastructure/views/user/Overview.vue")
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: "notFound",
        component: () => import("@/components/404.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const requireAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (!requireAuth) {
        next()
        return;
    }

    const role = localStorageData.getUserRole();

    const meta = to.meta as any;

    if (meta.allowedRoles.includes(role)) {
        next();
        return;
    }

    next({name: meta.redirect});
})

export default router
