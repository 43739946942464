import {ref} from "vue";

class Message {
    private message = ref<string>("");
    private color = ref<string>("");

    private timer: number | null = null;

    public showRequest() {
        this.message.value = "請稍後";
        this.color.value = "gray";
    }

    public showSuccess(success: string, timeout = 1500): Promise<void> | void {
        this.message.value = success;
        this.color.value = "bg-success";

        return this.createTimeOut(timeout);
    }

    public showError(error: string, timeout = 1500): Promise<void> | void {
        this.message.value = error;
        this.color.value = "bg-danger";

        return this.createTimeOut(timeout);
    }

    private createTimeOut(timeout: number): Promise<void> {
        if (this.timer)
            clearTimeout(this.timer);

        return new Promise<void>((resolve) => {
            this.timer = setTimeout(() => {
                this.message.value = "";
                resolve();
            }, timeout);
        });
    }

    //region Public Method

    public getMessage() {
        return this.message.value;
    }

    public getColor() {
        return this.color.value;
    }

    public hasMessage(): boolean {
        return this.message.value !== "";
    }

    //endregion
}

export default Message;