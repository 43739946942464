import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//style
import "@/style/media.css"

//bootstrap 5
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

//FontAwesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(fas)

createApp(App)
    .use(store)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
