import User from "@/user/domain/user";

class LocalStorageData {
    public user: User = User.createEmpty();

    constructor() {
        const localStorageUser = localStorage.getItem('user');

        this.user = localStorageUser
            ? User.fromJson(JSON.parse(localStorageUser))
            : User.createEmpty();
    }

    public setUser(user: User) {
        localStorage.setItem('user', user.toJson());

        this.user = user;
    }

    public logout() {
        localStorage.removeItem('user');

        this.user = User.createEmpty();
    }

    public getUserRole() {
        return this.user.getRole();
    }

    public getUserPhone() {
        return this.user.getPhone();
    }

    public isManager() {
        return this.user.isManager();
    }
}

export const localStorageData = new LocalStorageData();