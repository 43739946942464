export function startOfToday() {
    const date = new Date();
    date.setHours(0, 0, 0);
    return date;
}

export function endOfToday() {
    const date = new Date();
    date.setHours(23, 59, 59);
    return date;
}

export function formatToCalendar(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatToDatetime(time: number) {
    const date = new Date(time * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}

export function convertToStartOfDayTimestamp(dateString: string): number {
    const date = new Date(dateString);
    date.setHours(0, 0, 0);

    return Math.floor(date.getTime() / 1000);
}

export function convertToEndOfDayTimestamp(dateString: string): number {
    const date = new Date(dateString);
    date.setHours(23, 59, 59);

    return Math.floor(date.getTime() / 1000);
}